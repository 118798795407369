import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

interface IApiDataStrategyOptions extends StrategyOptions { }

declare class ApiDataStrategy extends self.workbox.strategies.Strategy {
    constructor(options: IApiDataStrategyOptions);

    _handle(request: Request, handler: typeof self.workbox.strategies.StrategyHandler): Promise<Response>;

    private handleRetrieve(parsedOptions: any): Promise<any>;
    private handleRetrieveRowcount(parsedOptions: any): Promise<any>;
    private handleCreate(parsedOptions: any): Promise<any>;
    private handleUpdate(parsedOptions: any): Promise<any>;
    private handleDestroy(parsedOptions: any): Promise<any>;
}


export type { ApiDataStrategy };